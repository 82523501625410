import { RemoveItemFromArray } from "./ArrayUtils";

export interface GlobalEvent {
    key: string,
    onTrigger: (value?:string) => void
}

export const GlobalEventManager = new ( class GlobalEventManagerClass{
    private sessionExpiredEvents: GlobalEvent[] = [];
    private webEvents: GlobalEvent[] = [];

    public SessionExpired = () => {
       this.sessionExpiredEvents.map(event => event.onTrigger())
    }

    AddSessionExpiredEvent = (event: GlobalEvent) => {
        const eventWithKey = this.sessionExpiredEvents.find((ge) => ge.key == event.key);
        if (eventWithKey) {
            RemoveItemFromArray(this.sessionExpiredEvents, eventWithKey);
        }

        this.sessionExpiredEvents.push(event);
    }

    RemoveSessionExpiredEvent = (event: GlobalEvent | string) => {
        let eventKey = "";
        if ((event as GlobalEvent).key) {
            eventKey = (event as GlobalEvent).key;
        }
        else {
            eventKey = event as string;
        }

        const eventWithKey = this.sessionExpiredEvents.find((ge) => ge.key == eventKey);
        if (eventWithKey) {
            RemoveItemFromArray(this.sessionExpiredEvents, eventWithKey);
        }
    }
    HasSessionExpiredEvents = () => this.sessionExpiredEvents.length > 0;

    ExecuteEvent = (eventName:string, param?:string) => {
        this.webEvents.filter(e => e.key == eventName)?.map(event => event.onTrigger(param))
    }

    AddEvent = (event: GlobalEvent) => {
        const eventWithKey = this.webEvents.find((ge) => ge.key == event.key);
        if (eventWithKey) {
            RemoveItemFromArray(this.webEvents, eventWithKey);
        }

        this.webEvents.push(event);
    }

    RemoveEvent = (event: GlobalEvent | string) => {
        let eventKey = "";
        if ((event as GlobalEvent).key) {
            eventKey = (event as GlobalEvent).key;
        }
        else {
            eventKey = event as string;
        }

        const eventWithKey = this.webEvents.find((ge) => ge.key == eventKey);
        if (eventWithKey) {
            RemoveItemFromArray(this.webEvents, eventWithKey);
        }
    }
    HasEvents = () => this.webEvents.length > 0;
})()