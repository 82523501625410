import * as React from "react";
import { ISvgProps } from "../../Definitions/ISvgProps";

export const FileCsvIcon: React.FC<ISvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width={props.width}
      height={props.height}
      style={props.style}
    >
      <rect
        width={props.width}
        height={props.height}
        style={{ fill: "none" }}
      />
      <path
        d="M29.89,9a4.73,4.73,0,0,0-.3-.82,5.2,5.2,0,0,0-.4-.74,4.07,4.07,0,0,0-.43-.55L23.08,1.29a3.68,3.68,0,0,0-.56-.42,4.92,4.92,0,0,0-.76-.39,4.9,4.9,0,0,0-.84-.29,3.13,3.13,0,0,0-.84-.11H3.75a1.72,1.72,0,0,0-.68.13,1.76,1.76,0,0,0-.56.37,1.58,1.58,0,0,0-.37.54A1.67,1.67,0,0,0,2,1.79v9H4.33V2.35h14V9.77a1.63,1.63,0,0,0,.13.67,1.75,1.75,0,0,0,.38.55,1.63,1.63,0,0,0,.56.36,1.72,1.72,0,0,0,.68.14h7.59V29.72H4.33V24H2v6.29a1.71,1.71,0,0,0,.14.67,1.68,1.68,0,0,0,.37.54,1.76,1.76,0,0,0,.56.37,1.72,1.72,0,0,0,.68.13h24.5a1.72,1.72,0,0,0,.68-.13,1.76,1.76,0,0,0,.56-.37,1.68,1.68,0,0,0,.37-.54,1.71,1.71,0,0,0,.14-.67V9.77A3.5,3.5,0,0,0,29.89,9Zm-9.23.26V2.51a2.54,2.54,0,0,1,.76.37L27.1,8.46a2.12,2.12,0,0,1,.41.75Z"
        style={{ fill: "#808488" }}
      />
      <rect
        width={props.width}
        height={props.height}
        style={{ fill: "none" }}
      />
      <rect
        width={props.width}
        height={props.height}
        style={{ fill: "none" }}
      />
      <path
        d="M0,6.17V26.55L16,29V4ZM9.66,21l-2-3.73h0L5.7,21H3l3.22-5.12L3.27,10.76H6l1.71,3.68h0l2-3.68h2.52l-3,5.07L12.34,21Z"
        style={{ fill: "#117d40" }}
      />
      <path
        d="M24.14,21.21H21.8V20.06h0a2.63,2.63,0,0,1-2.39,1.34,2.47,2.47,0,0,1-1.83-.66A2.35,2.35,0,0,1,16.88,19c0-1.56.92-2.45,2.76-2.69L21.81,16c0-.88-.47-1.32-1.42-1.32a4.86,4.86,0,0,0-2.72.86V13.67a6.58,6.58,0,0,1,1.41-.47,7.77,7.77,0,0,1,1.63-.2q3.43,0,3.43,3.42ZM21.81,18v-.54l-1.45.19c-.8.1-1.21.47-1.21,1.09a.89.89,0,0,0,.3.69,1.11,1.11,0,0,0,.79.27,1.48,1.48,0,0,0,1.14-.48A1.75,1.75,0,0,0,21.81,18Z"
        style={{ fill: "#127e40" }}
      />
      <path d="M27.61,19.54l-1.15,4H24.53l.8-4Z" style={{ fill: "#127e40" }} />
    </svg>
  );
};
